import React, {useEffect} from 'react'
import Head from 'next/head'
import {StyledHomePage} from '../shared/src/Screens/HomePage'
import {useHomePageActions} from 'src/hooks/homePage/useHomePageActions'
import HomePageSEO from 'src/components/SEO/HomePage'
import {useShowScrollToTop} from 'src/components/shared/src/hooks/useShowScrollToTop'
import ScrollToTopButton from 'src/components/shared/src/ComponentsV2/common/ScrollToTopButton'

import {
  BlogCards,
  CategoryListing,
  ExpertsOnFingerPrints,
  ProcessSteps,
  ProductFinder,
  RichProductsCards,
  Team,
  VideoShowCase,
  HeroBanner,
  CategoryGrid,
  Usp,
  Reviews,
  Testimonials,
  HowItWorks,
  PaymentOptions,
  DownloadApp,
  CategoryPalette,
  HomepageBanner,
  FeelingConfused,
  TrustMarkers,
  TrustMarkersV2,
  Expert,
  WhyLittleJoys,
  InFocusBanner,
  VideoSection,
  FirstFoldCarousel,
  OurStory,
  Gallery,
  QualityCheck,
  CustomerReviews,
  TestimonialsOld,
  BirthdayTracker,
  ConcernHelpFilters,
  ComplianceProgress,
} from './imports'
import {useAppConfigContext} from 'src/context/AppConfigContext'
import {useBirthdayTracker} from 'src/hooks/birthdayTracker/useBirthdayTracker'

import Slider from 'src/components/shared/src/ComponentsV2/Screens/HomePage/VideoShowCase/Slider'

const COMPONENT_MAP = {
  heroBannerGrid: HeroBanner,
  categoryGrid: CategoryGrid,
  productFinder: ProductFinder,
  blogCards: BlogCards,
  youMatter: Usp,
  team: Team,
  reviews: Reviews,
  testimonials: Testimonials,
  howItWorks: HowItWorks,
  paymentOptions: PaymentOptions,
  categories: CategoryListing,
  downloadApp: DownloadApp,
  expertCard: ExpertsOnFingerPrints,
  videoShowCase: Slider,
  richProductsCards: RichProductsCards,
  categoryPalette: CategoryPalette,
  homepageBanner: HomepageBanner,
  processSteps: ProcessSteps,
  feelingConfused: FeelingConfused,
  trustMarkers: TrustMarkers,
  trustMarkersV2: TrustMarkersV2,
  expert: Expert,
  whyLittleJoys: WhyLittleJoys,
  inFocusBanner: InFocusBanner,
  videoSection: VideoSection,
  firstFold: FirstFoldCarousel,
  ourStory: OurStory,
  gallery: Gallery,
  qualityCheck: QualityCheck,
  customerReviews: CustomerReviews,
  testimonialsOld: TestimonialsOld,
  birthdayTracker: BirthdayTracker,
  helpWithConcern: ConcernHelpFilters,
  complianceJourney: ComplianceProgress,
}

type ComponentType = keyof typeof COMPONENT_MAP

/**
 *
 * TODO: define types for HP
 */
function NewHomePage(props: any) {
  const {data: homepageProps} = props || {}
  const [birthdayTrackerState, birthdayTrackerActions] = useBirthdayTracker()
  const actions = useHomePageActions({birthdayTrackerActions})
  const {
    meta,
    organisationData,
    websiteSchemaData,
    showScrollToTop = false,
    showSmallProductCards = true,
    sections,
    preloadImage = '',
  } = homepageProps || {}

  const {nodeRef, showScrollToTopButton} = useShowScrollToTop()

  const [, appConfigContextActions] = useAppConfigContext()
  const {resetUpdateConfig, updateAppConfig} = appConfigContextActions

  useEffect(() => {
    updateAppConfig(homepageProps?.appConfig ?? {})
    return () => resetUpdateConfig()
  }, [homepageProps?.appConfig, resetUpdateConfig, updateAppConfig])

  const {order = []} = sections || {}

  return (
    <>
      <Head>
        {!!preloadImage && (
          <link rel="preload" href={preloadImage} as="image" />
        )}
      </Head>
      <div ref={nodeRef}></div>
      <HomePageSEO
        meta={meta}
        organisationData={organisationData}
        websiteSchemaData={websiteSchemaData}
      />
      <StyledHomePage>
        {order?.map((item: ComponentType, index: number) => {
          if (COMPONENT_MAP[item]) {
            const Component = COMPONENT_MAP[item]
            const inFirstFold = index <= 4
            let state = sections[item]
            if (item === 'birthdayTracker') {
              state = {...state, ...birthdayTrackerState}
            }
            return (
              <React.Fragment key={item}>
                <Component
                  state={state}
                  actions={actions}
                  rclTheme={showSmallProductCards}
                  inFirstFold={inFirstFold}
                />
              </React.Fragment>
            )
          } else {
            return null
          }
        })}
        {showScrollToTop && showScrollToTopButton && <ScrollToTopButton />}
      </StyledHomePage>
    </>
  )
}

export default NewHomePage
