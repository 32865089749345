import {usePost} from '@mosaic-wellness/core-utils'
import {IAddChildDataPayload} from '@mosaic-wellness/redux-action-library/build/interfaces/addChildData'

import {useCallback} from 'react'
import {API_END_POINTS} from 'src/constants/apiEndPoints'
import {IGlobalResponse} from 'src/interfaces/globalResponse.interface'
import {toast} from 'src/toast'

function useAddChildData() {
  const {isLoading, isSuccess, mutate} = usePost<
    IGlobalResponse<any>,
    any,
    IAddChildDataPayload
  >({
    url: API_END_POINTS.ADD_CHILD_DATA,
  })

  const saveChildData = useCallback(
    async (addChildDataPayload: IAddChildDataPayload): Promise<void> => {
      const {name} = addChildDataPayload

      mutate(addChildDataPayload, {
        onSuccess: () => {
          toast('success', `${name} added successfully`)
        },
        onError: () => {
          toast('warning', 'Some error Occurred')
        },
      })
    },
    [mutate]
  )

  return [
    {
      isSuccess,
      isLoading,
    },
    {
      saveChildData,
    },
  ] as const
}

export {useAddChildData}
