import {useCallback, useEffect, useRef, useState} from 'react'
import {useAuth, useUserStore} from '@mosaic-wellness/redux-action-library'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useGenericCta} from '../useGenericCta'
import {analytics} from 'src/analytics'

export interface ICta {
  action: string
  label: string
  link: string
  newTab: boolean
}

function useInFocusBanner() {
  const {handleCta} = useGenericCta()
  const {
    user: {isLoggedIn},
  } = useUserStore()

  const [link, setLink] = useState<ICta | null>(null)

  const inFocusRedirectionIntent = useRef(false)

  const {
    actions: {setShowLoginModal},
  } = useAuth()

  const inFocusBannerCta = useCallback(
    (cta: ICta) => {
      analytics.trigger(EVENT_MAP.ASSESSMENT_CARD_CLICKED, cta)
      handleCta(cta)
    },
    [handleCta]
  )

  const inFocusBannerClick = useCallback(
    (isLoginRequired: boolean, cta: ICta) => {
      if (isLoginRequired && !isLoggedIn) {
        inFocusRedirectionIntent.current = true
        setLink(cta)
        setShowLoginModal({isOpen: true, triggeredFrom: ''})
        return
      }
      inFocusBannerCta(cta)
    },
    [inFocusBannerCta, isLoggedIn, setShowLoginModal]
  )

  useEffect(() => {
    if (inFocusRedirectionIntent.current && link && isLoggedIn) {
      inFocusBannerCta(link)
    }
  }, [link, isLoggedIn, inFocusBannerCta])

  return {inFocusBannerClick}
}

export {useInFocusBanner}
