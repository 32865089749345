import {useCallback} from 'react'
import {analytics} from 'src/analytics'
import {EVENT_MAP} from 'src/analytics/eventMap'

export function useConcernHelp() {
  const getMappedCTAData = useCallback(
    (userSelectedFilters, ctaPermutations) => {
      const searchKeys = Object.values(userSelectedFilters)?.map(
        (filter: any) => filter?.key
      )
      let ctas = {}
      for (const permutation of ctaPermutations) {
        const {key = '', subFilter = []} = permutation || {}
        if (searchKeys.includes(key)) {
          for (const filter of subFilter) {
            const {key = ''} = filter || {}
            if (searchKeys.includes(key)) {
              ctas = filter
              break
            }
          }
        }
      }
      return ctas
    },
    []
  )

  const onConcernFilterOptionSelected = useCallback(
    (filterName, option, sectionName) => {
      analytics.trigger(EVENT_MAP.PRODUCT_FILTER_CLICKED, {
        filterName,
        optionsSelected: option,
        url: window.location?.href ?? '',
        sectionAppliedFor: sectionName,
      })
    },
    []
  )

  return {onConcernFilterOptionSelected, getMappedCTAData}
}
