import {useCallback} from 'react'
import {useExplicitATCTracking} from 'src/hooks/tracking/useExplicitATCTracking'
import {useGenericCta} from 'src/hooks/useGenericCta'
import {useProductCard} from '../../../src/hooks'
import {useUpdateItemToCart} from '../updateCart/useUpdateItemToCart'
import {useInFocusBanner} from '../inFocusBanner/useInFocusBanner'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useConcernHelp} from '../concernHelp/useConcernHelp'
import useNavigation from '../useNavigation/useNavigation'
import {analytics} from 'src/analytics'

export const useHomePageActions = ({birthdayTrackerActions}: any) => {
  const {addToCart} = useUpdateItemToCart()
  const [, {navigateTo}] = useNavigation()
  const {handleCta} = useGenericCta()
  const {trackAddToCart} = useExplicitATCTracking()
  const {onProductClick} = useProductCard()
  const {inFocusBannerClick} = useInFocusBanner()
  const {onConcernFilterOptionSelected, getMappedCTAData} = useConcernHelp()
  const handleAddToCart = useCallback(
    (product, source) => {
      const {sku} = product
      addToCart({sku})
      trackAddToCart(product, source)
    },
    [trackAddToCart, addToCart]
  )

  const handleProductNavigation = useCallback(
    (card, source) => {
      onProductClick(card, source)
    },
    [onProductClick]
  )

  const onMedicalCardCtaClick = useCallback(
    (link) => {
      navigateTo(link)
    },
    [navigateTo]
  )

  const onTagClick = useCallback(
    (link = '', analyticsData = {}, title = '') => {
      const {eventData = {}} = analyticsData
      analytics.trigger(EVENT_MAP.CTA_CLICKED, {
        ...eventData,
        label: title,
        link,
      })
      handleCta({action: 'SPA_LINK', link})
    },
    [handleCta]
  )

  const handleAnalytics = useCallback((action, data) => {
    analytics.trigger(action, data)
  }, [])

  return {
    onAddToCart: handleAddToCart,
    onCtaClick: handleCta,
    onProductClick: handleProductNavigation,
    onNavigation: handleProductNavigation,
    handleCta,
    onMedicalCardCtaClick,
    onTagClick,
    handleAnalytics,
    inFocusBannerClick,
    ...birthdayTrackerActions,
    onConcernFilterOptionSelected,
    getMappedCTAData,
  }
}
