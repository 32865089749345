import {useUserStore} from '@mosaic-wellness/redux-action-library'
import {useCallback, useEffect, useState} from 'react'
import {EVENT_MAP} from 'src/analytics/eventMap'
import {useAddChildData} from '../useAddChildData/useAddChildData'
import {analytics} from 'src/analytics'

function useBirthdayTracker() {
  const {
    user: {isLoggedIn},
  } = useUserStore()

  const [, {saveChildData}] = useAddChildData()

  const [showBirthdayTracker, setShowBirthdayTracker] = useState(false)
  const [noOfKidsSave, setNoOfKidsSaved] = useState(1)

  const onBirthdayTrackerSeen = useCallback(() => {
    analytics.trigger(EVENT_MAP.BIRTHDAY_TRACKER_SEEN, {})
  }, [])

  const onBirthdayTrackerAddChildClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.BIRTHDAY_TRACKER_ADD_CHILD_CLICKED, {})
  }, [])

  const onBirthdayTrackerSaved = useCallback((data) => {
    analytics.trigger(EVENT_MAP.BIRTHDAY_TRACKER_CHILD_SAVED, data)
  }, [])

  const onBirthdayTrackerCancelClicked = useCallback(() => {
    analytics.trigger(EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CANCEL, {})
  }, [])

  const onBirthdayTrackerCancelClosed = useCallback(() => {
    analytics.trigger(EVENT_MAP.BIRTHDAY_TRACKER_CHILD_CLOSED, {})
  }, [])

  const saveKidsData = useCallback(
    (data: any) => {
      onBirthdayTrackerSaved({...data, noOfKidsSave})
      setNoOfKidsSaved((noOfKidsSave) => noOfKidsSave + 1)
      const {name = '', date = '', gender} = data
      saveChildData({name, dob: date, gender})
      window.localStorage.setItem('birthdayTrackerViewed', JSON.stringify(true))
    },
    [noOfKidsSave, onBirthdayTrackerSaved, saveChildData]
  )

  const handleCloseClick = useCallback(() => {
    onBirthdayTrackerCancelClosed()
    setShowBirthdayTracker(false)
  }, [onBirthdayTrackerCancelClosed])

  useEffect(() => {
    if (
      isLoggedIn &&
      !(
        typeof window !== 'undefined' &&
        JSON.parse(
          window.localStorage.getItem('birthdayTrackerViewed') || 'false'
        )
      )
    ) {
      setShowBirthdayTracker(true)
    }
  }, [isLoggedIn])

  return [
    {
      showBirthdayTracker,
    },
    {
      onBirthdayTrackerSeen,
      onBirthdayTrackerAddChildClicked,
      onBirthdayTrackerCancelClicked,
      saveKidsData,
      handleCloseClick,
    },
  ]
}

export {useBirthdayTracker}
